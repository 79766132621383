.peacresjs-footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-top: 2px solid #e9711c;

  .c-peacres-footer__bold-link {
    color: #505050;
    font-weight: 700;
  }

  .c-els-footer__logo {
    grid-area: logo;
    width: 140px;
  }

  .c-els-footer__content {
    grid-area: content;
  }

  .c-els-footer__parent-logo {
    grid-area: parent-logo;
    width: 140px;
  }

  .c-peacres-footer__content-grid {
    display: grid;
    grid-gap: 1em;
    grid-template-areas: ". . .";
    grid-template-columns: fit-content(20%) fit-content(20%) auto
  }

  .c-peacres-footer__grid {
    display: grid;
    grid-gap: 1em;
    margin: 2em 3em;

    .footer-content-item {
      margin-top: 1em;
    }
  }

  @media only screen and (min-width: 550px) {
    .c-peacres-footer__grid {
      grid-template-areas: "logo content" ". parent-logo" ". . ";
      grid-template-columns: 150px auto;
      grid-template-rows: auto auto 0;
      grid-gap: 0;
    }

    .c-els-footer__parent-logo {
      justify-self: end;
    }

    .c-els-footer__content {
      margin-left: 2em;
    }
  }

  @media only screen and (max-width: 550px) {
    .c-peacres-footer__grid {
      display: grid;

      grid-template-areas: "logo ." "content ." "parent-logo .";

    }

    .c-els-footer__parent-logo {
      justify-self: end;
    }
  }
}

@media only screen and (max-width: 550px) {
  .peacresjs-footer {
    height: 230px;
  }
}

@media only screen and (min-width: 550px) {
  .peacresjs-footer {
    height: 144px;
  }
}